import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, InjectionToken, OnInit, PLATFORM_ID, computed, signal } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogClose,
  MatDialogContent,
} from '@angular/material/dialog';
import { lockerCleanAddress } from '../../../../helpers/functions';
import { ApiService } from '../../../../services/api/api.service';
import { HttpParams } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MeterGroupModule } from 'primeng/metergroup';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { AvatarModule } from 'primeng/avatar';
import { AccordionModule } from "primeng/accordion";
import { DividerModule } from "primeng/divider";
import { CBox_LockerBoxCount, CBox_GetLockerListDataItem, CBox_GetLockerDataResponse } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { CBoxProfilePickupRequestInfoComponent } from 'app/profile/requests/pickup/dialogs/info/cbox-profile-pickup-request-info.component';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_GetPickUpDataResponse } from '@server/services/cbox/public/api/v1/resources/pick_up/types';
import { CBoxProfileDropOffRequestInfoComponent } from 'app/profile/requests/drop-off/dialogs/info/cbox-profile-dropoff-request-info.component';
import { SkeletonModule } from 'primeng/skeleton';
import { LeafletService } from 'src/services/leaflet/leaflet.service';
import { PermissionNamesEnum } from '@server/services/cbox/public/api/v1/enforcers/entity_permission/types';
import { PermissionDirective } from 'src/directives/permission.directive';

@Component({
  selector: 'app-profile-locker-data',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogClose,
    ButtonModule,
    CardModule,
    MeterGroupModule,
    TagModule,
    TableModule,
    AvatarModule,
    AccordionModule,
    DividerModule,
    SkeletonModule,
    PermissionDirective
  ],
  providers: [
    LeafletService
  ],
  templateUrl: './cbox-profile-locker-data.component.html',
  styleUrl: './cbox-profile-locker-data.component.scss'
})
export class CBoxProfileLockerDataComponent implements OnInit, AfterViewInit {

  locker = signal<CBox_GetLockerDataResponse | undefined>(undefined);
  fetching = signal<"fetching" | "done" | "error">("fetching");
  doorLabels: {[key in keyof CBox_GetLockerDataResponse["boxes"] | string]: {
    label: string;
    icon: string;
    color: string;
  }} = {
    available: {
      label: "libere",
      icon: "check",
      color: "var(--green-500)"
    },
    occupied: {
      label: "ocupate",
      icon: "lock",
      color: "var(--red-500)"
    },
    reserved: {
      label: "rezervate",
      icon: "event",
      color: "var(--yellow-500)"
    }
  };

  pickUpOrderLabels: {
    [key in keyof CBox_GetLockerDataResponse['requests']['pickUp'] &
         CBox_GetLockerDataResponse['requests']['dropOff'] |
     string]: {label: string;}
  } = {
    active: {label: 'Active, în locker'},
    expired: {label: 'Expirate, în locker'},
    incoming: {label: 'De încarcat în locker'}
  }

  dropOffOrderLabels: {
    [key in keyof CBox_GetLockerDataResponse['requests']['pickUp'] &
         CBox_GetLockerDataResponse['requests']['dropOff'] |
     string]: {label: string;}
  } = {
    deposited: {label: 'În locker'},
    incoming: {label: 'Rezervate'}
  }

  lockerFreeBoxes = computed(() => {
    if (!this.locker()) {
      return 0;
    }
    const availableBoxes = this.locker()?.boxes.available.reduce((acc: number, box: {
      size: string;
      count: number;
    }) => acc + box.count, 0);

    return availableBoxes;
  });

  lockerBusyBoxes = computed(() => {
    if (!this.locker()) {
      return 0;
    }
    const busyBoxes = this.locker()!.boxes.occupied.reduce((acc: number, box: {
      size: string;
      count: number;
    }) => acc + box.count, 0);

    return busyBoxes + this.lockerReservedBoxes();
  });

  lockerReservedBoxes = computed(() => {
    if (!this.locker()) {
      return 0;
    }
    const reservedBoxes = this.locker()!.boxes.reserved.reduce((acc: number, box: {
      size: string;
      count: number;
    }) => acc + box.count, 0);

    return reservedBoxes;
  });

  lockerBoxes = computed(() => {
    if (!this.locker()) {
      return [];
    }

    return [
      ...this.locker()!.boxes.available,
      ...this.locker()!.boxes.reserved,
      ...this.locker()!.boxes.occupied
    ];
  });

  permissions = PermissionNamesEnum;

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private leaflet: LeafletService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    @Inject(MAT_DIALOG_DATA) private lockerName: string) {}

  ngOnInit(): void {
    this.fetchLockerData();
  }

  ngAfterViewInit(): void {}

  formatLockerAddress(address: CBox_GetLockerListDataItem["addressParts"] | undefined): string {
    if (!address) {
      return "";
    }
    return lockerCleanAddress(address);
  }

  getBoxesCount(boxType: keyof CBox_GetLockerListDataItem["boxes"] | string): number {
    const boxes = this.locker()?.boxes[boxType as keyof CBox_GetLockerListDataItem["boxes"]];
    if (!boxes) {
      return 0;
    }
    return boxes.reduce((acc: number, box: CBox_LockerBoxCount) => acc + box.count, 0);
  }

  openPickUpRequestInfo(order: CBox_GetPickUpDataResponse): void {
    this.dialog.open(CBoxProfilePickupRequestInfoComponent, {
      data: order,
      minWidth: "min(1200px, 100%)",
      autoFocus: false,
      disableClose: true
    });
  }

  openDropOffRequestInfo(order: CBox_GetPickUpDataResponse): void {
    this.dialog.open(CBoxProfileDropOffRequestInfoComponent, {
      data: order,
      minWidth: "min(1200px, 100%)",
      autoFocus: false,
      disableClose: true
    });
  }

  openLockerDetails(): void {
    this.dialog.open(CBoxProfileLockerDataComponent, {
      data: this.locker.name,
      minWidth: "min(1200px, 100%)"
    })
  }

  private fetchLockerData(): void {
    if (isPlatformBrowser(this.platformId)) {
      const query = new HttpParams().append("name", this.lockerName);
      this.api.get<CBox_PublicSuccessResponse<CBox_GetLockerDataResponse>>("backend/locker", query).subscribe((locker) => {
        this.locker.set(locker.data);
        this.fetching.set("done");
      }, () => {
        this.fetching.set("error");
      });
    }
  }

}
