import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-cbox-profile-pick-up-request-sms-send-confirmation-dialog',
  templateUrl: './cbox-profile-pick-up-request-sms-send-confirmation-dialog.component.html',
  styleUrls: ["./cbox-profile-pick-up-request-sms-send-confirmation-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose
  ]
})

export class CBoxProfilePickUpRequestSmsSendConfirmationDialogComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}