<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Retrimitere email</h2>
  <p-button
    mat-dialog-close
    size="small"
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  Confirmați retrimiterea email-ului?
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex align-items-center gap-2">
    <p-button
      mat-dialog-close
      size="small"
      label="Anulează"
      [text]="true"
    />
    <p-button
      size="small"
      icon="pi pi-send"
      iconPos="left"
      label="Trimite"
      [mat-dialog-close]="true"
    />
  </div>
</mat-dialog-actions>