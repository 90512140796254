import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_ActionUpdatePickUpParams, CBox_GetPickUpDataResponse } from "@server/services/cbox/public/api/v1/resources/pick_up/types";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from "primeng/tooltip";

@Component({
  selector: "app-cbox-profile-pick-up-request-reactivate-dialog",
  templateUrl: "./cbox-profile-pick-up-request-reactivate-dialog.component.html",
  styleUrls: ["./cbox-profile-pick-up-request-reactivate-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonModule,
    ProgressSpinnerModule,
    CalendarModule,
    ReactiveFormsModule,
    SkeletonModule
  ]
})

export class CBoxProfilePickUpRequestReactivateDialogComponent implements OnInit {

  orderData = signal<CBox_GetPickUpDataResponse | undefined>(undefined);
  extendDate = new FormControl(new Date(), Validators.required);
  today = signal(new Date());

  fetching = signal(true);
  reactivating = signal(false);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfilePickUpRequestReactivateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private responseId: string,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) { }

  ngOnInit(): void {
    this.init();
  }

  reactivateOrder(): void {
    if (this.extendDate.invalid) {
      this.toastService.showErrorToast("Eroare", "Data de expirare este invalidă!");
      return;
    }
    this.reactivating.set(true);
    this.api.patch("backend/request/pick-up?responseId=" + this.responseId, this.getReactivateStructure()).subscribe(() => {
      this.dialogRef.close({
        reactivated: true
      });
      this.toastService.showSuccessToast("Confirmare", "Comanda a fost reactivată cu succes!");
      this.reactivating.set(false);
    }, (e: HttpErrorResponse) => {
      this.reactivating.set(false);
      handlePublicApiError(e, this.toastService);
    })
  }

  private async init(): Promise<void> {
    const tomorrow = new Date().setDate(new Date().getDate() + 1);
    this.extendDate.patchValue(new Date(tomorrow));
    if (isPlatformBrowser(this.platformId)) {
      this.fetchOrderData();
    }
  }

  private fetchOrderData(): void {
    this.api.get<CBox_PublicSuccessResponse<CBox_GetPickUpDataResponse>>("backend/request/pick-up?responseId=" + this.responseId).subscribe((response) => {
      this.orderData.set(response.data);
      this.fetching.set(false);
    }, (e: HttpErrorResponse) => {
      this.fetching.set(false);
      this.dialogRef.close();
      handlePublicApiError(e, this.toastService);
    });
  }

  private getReactivateStructure(): CBox_ActionUpdatePickUpParams["body"] {
    return {
      retention: {
        reenable: true,
        extend: {
          until: new Date(this.extendDate?.value as Date).toISOString()
        }
      }
    }
  }
}